<template>
  <v-footer class="d-flex bg-primary flex-column  footer" height="120px"  >
    <v-card-title class="section-title">КОНТАКТЫ</v-card-title>
    <div class="footer-mobiles">
      <a href="tel:+998971599099" class="title">+998 97 159 90 99</a>
      <a href="tel:+998950509099 " class="title">+998 95 050 9099 </a>
      <a href="tel:+998882009099" class="title">+998 88 200 9099</a>
    </div>
  </v-footer>
</template>
<script>
export default {
  data: () => ({}),
}
</script>

<template>
  <v-card-title class="section-title gallery-title">
    404 Страница не найдена!
  </v-card-title>
  <h1 class="hidden__message">I'M LOST PLEASE ANYBODY CALL PSYCHOLOGIST TO <a href="https://t.me/iibratik">iibratik</a></h1>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>

<template>
  <v-card-title class="section-title contacts-title gallery-title">КОНТАКТЫ</v-card-title>
  <div class="contacts">
    <div class="wrapper">
      <a href="tel:+998971599099" class=" section-title gallery-title title">+998 97 159 90 99</a>
    </div>
    <div class="wrapper">
      <a href="tel:+998950509099 " class=" section-title gallery-title title">+998 95 050 9099 </a>
    </div>
    <div class="wrapper">
      <a href="tel:+998882009099" class=" section-title gallery-title title">+998 88 200 9099</a>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
